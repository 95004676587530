import React, { useState } from "react";

import { PenIcon } from "../Icons/PenIcon";

import { MediumIcon } from "../Icons/MediumIcon";

import { TwitterIcon } from "../Icons/TwitterIcon";

import { DiscordIcon } from "../Icons/DiscordIcon";

import { InstagramIcon } from "../Icons/InstagramIcon";

import { ChevronIcon } from "../Icons/ChevronIcon";

import { ArrowIcon } from "../Icons/ArrowIcon";

import { MigrateSparkIcon } from "../Icons/MigrateSparkIcon";

import { BookIcon } from "./BookIcon";

import { PawIcon } from "../Icons/PawIcon";

export const MoreTab = () => {
  const [moreHovered, setMoreHovered] = useState<boolean>(false);

  return (
    <div
      onMouseOver={() => setMoreHovered(true)}
      onMouseLeave={() => setMoreHovered(false)}
      className="header-nav-button relative mr-10 flex cursor-pointer text-xxs text-white hover:text-pink"
    >
      <p className="font-bonnbf uppercase tracking-header">more</p>

      {moreHovered ? (
        <ChevronIcon iconClass={"ml-2 fill-pink -rotate-90 w-2"}></ChevronIcon>
      ) : (
        <ChevronIcon iconClass={"ml-2 fill-pink rotate-90 w-2"}></ChevronIcon>
      )}

      {moreHovered && (
        <div className="absolute left-0 top-10 z-modal flex w-72 flex-col items-start justify-center gap-2 rounded-xl border border-white-10 bg-purple p-2 font-montserrat text-white">
          <div
            onClick={() => {
              window.open(
                "https://marketplace.dogami.com/collection/migrate",
                "_blank"
              );
            }}
            className="group flex w-full items-center justify-start gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
          >
            <MigrateSparkIcon
              iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
            ></MigrateSparkIcon>
            Migration
          </div>

          <div
            onClick={() => {
              window.open("https://marketplace.dogami.com/comics", "_blank");
            }}
            className="group flex w-full items-center justify-start gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
          >
            <BookIcon
              iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
            ></BookIcon>
            Comics
          </div>

          <div className="my-0.5 flex w-full items-center justify-start border-b border-white-10"></div>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://whitepaper.dogami.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <PenIcon
                iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
              ></PenIcon>
              Whitepaper
            </div>

            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://dogami.medium.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <MediumIcon
                iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
              ></MediumIcon>
              Medium
            </div>

            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://discord.com/invite/dogamiofficial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <DiscordIcon
                iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
              ></DiscordIcon>
              Discord
            </div>

            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://twitter.com/dogami"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <TwitterIcon
                iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
              ></TwitterIcon>
              Twitter
            </div>

            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://www.instagram.com/dogami/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <InstagramIcon
                iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
              ></InstagramIcon>
              Instagram
            </div>

            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://get.unstoppabledomains.com/doga/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <PawIcon
                iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
              ></PawIcon>
              .DOGA Domains
            </div>

            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
        </div>
      )}
    </div>
  );
};
