import HeaderConnectWalletSection from "../HeaderConnectWalletSection";

import logo from "../../assets/logo/logo_v2.png";

import { MenuIcon } from "../Icons/MenuIcon";

import { useEffect, useState } from "react";

import { CloseIcon } from "../Icons/CloseIcon";

import { UserIcon } from "../UserIcon";

import HeaderLinks from "./HeaderLinks";

import { ChevronIcon } from "../Icons/ChevronIcon";

import { ArrowIcon } from "../Icons/ArrowIcon";

import { MigrateSparkIcon } from "../Icons/MigrateSparkIcon";

import { PenIcon } from "../Icons/PenIcon";

import { MediumIcon } from "../Icons/MediumIcon";

import { DiscordIcon } from "../Icons/DiscordIcon";

import { TwitterIcon } from "../Icons/TwitterIcon";

import { InstagramIcon } from "../Icons/InstagramIcon";

import opensea from "../../assets/logo/opensea.svg";

import objkt from "../../assets/logo/objkt.svg";

import { StakingIcon } from "../Icons/StakingIcon";

import dogaLogo from "../../assets/icon/dogaLogo.svg";

import { AppleIcon } from "../Icons/AppleIcon";

import { QuestionIcon } from "../Icons/QuestionIcon";

import { BookIcon } from "./BookIcon";

import dogaToken from "../../assets/image/doga.png";

import { TrophyIcon } from "../Icons/TrophyIcon";

import { GoogleIcon } from "../Icons/GoogleIcon";

import { BridgeIcon } from "../Icons/BridgeIcon";

import magicEden from "../../assets/logo/magiceden.svg";

import { PawIcon } from "../Icons/PawIcon";

function NewsBanner() {
  const [display, setDisplay] = useState(false); // Initialize as hidden

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplay(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`transform rounded-b-xl py-2 transition-all duration-500 ease-out  ${
        display
          ? "translate-y-0 opacity-100"
          : "h-0 -translate-y-full overflow-hidden opacity-0"
      } sm:flex-col sm:items-start flex items-center bg-pink-50 px-12 text-xs`}
    >
      <span className="sm:w-full">
        Get your personalised .DOGA domain now and become a real Dogamer:&nbsp;
        <a
          href={"https://get.unstoppabledomains.com/doga/"}
          target={"_blank"}
          className={"underline-offset-2 underline"}
          rel="noreferrer"
        >
          Claim here!
        </a>
      </span>
      <div className={"sm:ml-0 sm:mt-2 ml-auto"}>
        <button
          onClick={() => setDisplay(false)}
          className="-m-4 p-4"
          aria-label="Close"
        >
          <CloseIcon iconClass="w-4 fill-white" />
        </button>
      </div>
    </div>
  );
}

export const Header = () => {
  const [openMenuModal, setOpenMenuModal] = useState<boolean>(false);

  const [marketplaceTabOpen, setMarketplaceTabOpen] = useState(false);

  const [playTabOpen, setPlayTabOpen] = useState(false);

  const [moreTabOpen, setMoreTabOpen] = useState(false);

  const [dogaTabOpen, setDogaTabOpen] = useState(false);

  const onGoToPage = (page: string) => {
    setOpenMenuModal(false);
    window.open(`https://marketplace.dogami.com/${page}`);
  };

  useEffect(() => {
    const handleEscape = (event: any) => {
      if (event.key === "Escape") {
        setOpenMenuModal(false);
      }
    };

    window.addEventListener("keydown", handleEscape);

    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [setOpenMenuModal]);

  return (
    <main>
      <div
        style={{
          backgroundImage:
            "linear-gradient(270deg, rgba(255, 0, 96, 0.3) 0%, rgba(17, 203, 237, 0.3) 100%), radial-gradient(rgba(20, 68, 255, 1) 0%, rgba(20, 68, 255, 0) 100%);, radial-gradient(rgba(255, 0, 96, 1) 0%, rgba(255, 0, 96, 0) 100%)",
          backgroundColor: "rgba(34, 2, 52, 0.7)",
        }}
        className="fixed z-pending-modal w-full"
      >
        <div className="w-full backdrop-blur-lg backdrop-filter">
          <div className="mx-auto my-0 max-w-xxxl">
            <div className="flex h-16 w-full items-center justify-between px-6">
              <div
                onClick={() => setOpenMenuModal(true)}
                className="flex cursor-pointer items-center justify-center laptop:hidden"
              >
                <MenuIcon iconClass="fill-white w-5 mr-4"></MenuIcon>
              </div>

              <img src={logo} className="w-36" />
              <HeaderConnectWalletSection />
            </div>
          </div>
        </div>

        <div className="hidden h-12 w-full border-b border-white-10 bg-purple laptop:block">
          <div className="mx-auto my-0 flex h-full max-w-xxxl items-start justify-start px-6">
            <HeaderLinks></HeaderLinks>
          </div>
        </div>
        <div>
          <NewsBanner />
        </div>
      </div>

      {openMenuModal && (
        <div className="outline-none fixed inset-0 z-pending-modal flex items-center justify-center overflow-y-auto overflow-x-hidden bg-purple">
          <div className="flex h-full w-full animate-fadeInUp flex-col items-start justify-start">
            <div className="flex w-full items-center justify-between px-6 py-3">
              <div
                onClick={() => setOpenMenuModal(false)}
                className="z-pending-modal flex cursor-pointer items-center justify-center text-xs font-semibold text-white"
              >
                <CloseIcon iconClass="w-4 fill-white"></CloseIcon>
              </div>

              <div className="z-pending-modal cursor-pointer items-center justify-center">
                <img src={logo} className="w-36" alt="close" />
              </div>

              <UserIcon iconClass="cursor-pointer fill-white w-5"></UserIcon>
            </div>

            <div className="flex w-full flex-col items-start justify-start">
              <div
                onClick={() => {
                  setPlayTabOpen(!playTabOpen);
                  setMoreTabOpen(false);
                  setMarketplaceTabOpen(false);
                  setDogaTabOpen(false);
                }}
                className="flex w-full cursor-pointer items-center justify-between border-t border-t-white-10 p-5 font-bonnbf text-xxs font-semibold uppercase tracking-header text-white hover:text-pink"
              >
                <p> academy</p>

                <ChevronIcon
                  iconClass={
                    !playTabOpen ? "fill-pink w-3" : "fill-pink w-3 -rotate-90"
                  }
                ></ChevronIcon>
              </div>

              {playTabOpen && (
                <>
                  <div
                    onClick={() =>
                      window.open("https://dogami.com/game-deck", "_blank")
                    }
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <TrophyIcon
                        iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
                      ></TrophyIcon>
                      Game Deck
                    </div>
                  </div>

                  <div className="my-0.5 flex w-full items-center justify-start border-b border-white-10"></div>

                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
                    href="https://apps.apple.com/app/id1670203326"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <AppleIcon iconClass="group-hover:fill-pink fill-white w-4 mr-2"></AppleIcon>
                      Download on iOS
                    </div>
                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>
                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
                    href="https://play.google.com/store/apps/details?id=io.dogami.game.academy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <GoogleIcon iconClass="group-hover:fill-pink fill-white w-4 mr-2"></GoogleIcon>
                      Download on Android
                    </div>
                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>
                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
                    href="https://www.dogami.com/page/dogami-academy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <QuestionIcon iconClass="group-hover:fill-pink fill-white w-4 mr-2"></QuestionIcon>
                      How to Play
                    </div>
                  </a>
                </>
              )}

              <div
                onClick={() => {
                  setMarketplaceTabOpen(!marketplaceTabOpen);
                  setMoreTabOpen(false);
                  setDogaTabOpen(false);
                  setPlayTabOpen(false);
                }}
                className="flex w-full cursor-pointer items-center justify-between border-t border-t-white-10 p-5 font-bonnbf text-xxs font-semibold uppercase tracking-header text-white hover:text-pink"
              >
                <p> marketplace</p>

                <ChevronIcon
                  iconClass={
                    !marketplaceTabOpen
                      ? "fill-pink w-3"
                      : "fill-pink w-3 -rotate-90"
                  }
                ></ChevronIcon>
              </div>

              {marketplaceTabOpen && (
                <>
                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-3 rounded-md px-5 py-3 text-xs hover:bg-pink-10"
                    href="https://opensea.io/dogami_Deployer/created"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <img className="w-5" src={opensea} alt="opensea" />
                      Opensea
                    </div>
                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>

                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-3 rounded-md px-5 py-3 text-xs hover:bg-pink-10"
                    href="https://magiceden.io/collections/polygon/0xa3f2d95ff09ef87eb228d1aa965e06db4e9ce71b"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <img className="w-5" src={magicEden} alt="magicEden" />
                      Magic Eden
                    </div>
                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>

                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-3 rounded-md px-5 py-3 text-xs hover:bg-pink-10"
                    href="https://objkt.com/collection/dogami"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <img className="w-5" src={objkt} alt="objkt" />
                      Objkt
                    </div>
                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>
                </>
              )}

              <div
                onClick={() => onGoToPage("collection/dogami")}
                className="w-full cursor-pointer border-t border-t-white-10 p-5 font-bonnbf text-xxs font-semibold uppercase tracking-header text-white hover:text-pink"
              >
                my collection
              </div>

              <div
                onClick={() => onGoToPage("pet-sitting/shared-rewards")}
                className="relative w-full cursor-pointer border-t border-t-white-10 p-5 font-bonnbf text-xxs font-semibold uppercase tracking-header text-white hover:text-pink"
              >
                pet-sitting
              </div>

              <div
                onClick={() => {
                  setDogaTabOpen(!dogaTabOpen);
                  setMoreTabOpen(false);
                  setMarketplaceTabOpen(false);
                  setPlayTabOpen(false);
                }}
                className={
                  dogaTabOpen
                    ? "flex w-full cursor-pointer items-center  justify-between border-t border-t-white-10 p-5 font-bonnbf text-xxs font-semibold uppercase tracking-header text-white hover:text-pink"
                    : "flex w-full cursor-pointer items-center  justify-between border-b border-t border-b-white-10 border-t-white-10 p-5 font-bonnbf text-xxs font-semibold uppercase tracking-header text-white hover:text-pink"
                }
              >
                <p> $doga</p>

                <ChevronIcon
                  iconClass={
                    !dogaTabOpen ? "fill-pink w-3" : "fill-pink w-3 -rotate-90"
                  }
                ></ChevronIcon>
              </div>

              {dogaTabOpen && (
                <>
                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
                    href="https://marketplace.dogami.com/doga-token"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <img
                        className="mr-1 w-5"
                        alt="doga token"
                        src={dogaToken}
                      />
                      Learn about $DOGA
                    </div>
                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>

                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
                    href="https://staking.dogami.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <StakingIcon
                        iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
                      ></StakingIcon>
                      Stake $DOGA
                    </div>
                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>
                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
                    href="https://bridge.dogami.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <BridgeIcon
                        iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
                      ></BridgeIcon>
                      Bridge $DOGA
                    </div>
                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>

                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
                    href="https://snapshot.org/#/dogami-allies.eth"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <UserIcon
                        iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
                      ></UserIcon>
                      DOGAMI A.L.L.I.E.S (DAO){" "}
                    </div>
                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>
                </>
              )}

              <div
                onClick={() => {
                  setMoreTabOpen(!moreTabOpen);
                  setMarketplaceTabOpen(false);
                  setDogaTabOpen(false);
                  setPlayTabOpen(false);
                }}
                className={
                  moreTabOpen
                    ? "flex w-full cursor-pointer items-center  justify-between border-t border-t-white-10 p-5 font-bonnbf text-xxs font-semibold uppercase tracking-header text-white hover:text-pink"
                    : "flex w-full cursor-pointer items-center  justify-between border-b border-t border-b-white-10 border-t-white-10 p-5 font-bonnbf text-xxs font-semibold uppercase tracking-header text-white hover:text-pink"
                }
              >
                <p> more</p>

                <ChevronIcon
                  iconClass={
                    !moreTabOpen ? "fill-pink w-3" : "fill-pink w-3 -rotate-90"
                  }
                ></ChevronIcon>
              </div>

              {moreTabOpen && (
                <>
                  <div
                    onClick={() => onGoToPage("collection/migrate")}
                    className="group flex w-full cursor-pointer items-center justify-start gap-2 rounded-md px-5 py-3 text-xs hover:bg-pink-10"
                  >
                    <MigrateSparkIcon
                      iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
                    ></MigrateSparkIcon>
                    Migration
                  </div>

                  <div
                    onClick={() => onGoToPage("comics")}
                    className="group flex w-full cursor-pointer items-center justify-start gap-2 rounded-md px-5 py-3 text-xs hover:bg-pink-10"
                  >
                    <BookIcon
                      iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
                    ></BookIcon>
                    Comics
                  </div>

                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-5 py-3 text-xs hover:bg-pink-10"
                    href="https://whitepaper.dogami.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <PenIcon
                        iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
                      ></PenIcon>
                      Whitepaper
                    </div>

                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>

                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-5 py-3 text-xs hover:bg-pink-10"
                    href="https://dogami.medium.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <MediumIcon
                        iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
                      ></MediumIcon>
                      Medium
                    </div>

                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>

                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-5 py-3 text-xs hover:bg-pink-10"
                    href="https://discord.com/invite/dogamiofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <DiscordIcon
                        iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
                      ></DiscordIcon>
                      Discord
                    </div>

                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>

                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-5 py-3 text-xs hover:bg-pink-10"
                    href="https://twitter.com/dogami"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <TwitterIcon
                        iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
                      ></TwitterIcon>
                      Twitter
                    </div>

                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>

                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-5 py-3 text-xs hover:bg-pink-10"
                    href="https://www.instagram.com/dogami/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <InstagramIcon
                        iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
                      ></InstagramIcon>
                      Instagram
                    </div>

                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>
                  <a
                    className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-5 py-3 text-xs hover:bg-pink-10"
                    href="https://get.unstoppabledomains.com/doga/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex items-center justify-center gap-2 text-xs">
                      <PawIcon
                        iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
                      ></PawIcon>
                      .DOGA Domains
                    </div>

                    <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
                  </a>
                </>
              )}

              <div
                onClick={() => onGoToPage("doga-token")}
                className="relative w-full cursor-pointer border-b border-white-10 p-5 font-bonnbf text-xxs font-semibold uppercase tracking-header text-white hover:text-pink"
              >
                get $DOGA
              </div>

              <div
                onClick={() => onGoToPage("shop")}
                className="relative w-full cursor-pointer border-b border-white-10 p-5 font-bonnbf text-xxs font-semibold uppercase tracking-header text-white hover:text-pink"
              >
                shop
              </div>

              <div
                onClick={() => onGoToPage("notifications")}
                className="relative w-full cursor-pointer border-b border-white-10 p-5 font-bonnbf text-xxs font-semibold uppercase tracking-header text-white hover:text-pink"
              >
                notification
              </div>
            </div>
            <div className="absolute bottom-4 left-0 flex w-full items-center justify-center">
              <img className="fill-white w-12" src={dogaLogo} alt="dogaLogo" />
            </div>
          </div>
        </div>
      )}
      <div className="absolute w-full">
        <NewsBanner />
      </div>
    </main>
  );
};
